import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAlarmClock } from '@fortawesome/pro-regular-svg-icons/faAlarmClock';
import { faBell } from '@fortawesome/pro-regular-svg-icons/faBell';
import { faCloudUpload } from '@fortawesome/pro-regular-svg-icons/faCloudUpload';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import { faFolderOpen } from '@fortawesome/pro-regular-svg-icons/faFolderOpen';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import { faKey } from '@fortawesome/pro-regular-svg-icons/faKey';
import { faParking } from '@fortawesome/pro-regular-svg-icons/faParking';
import { faPencil } from '@fortawesome/pro-regular-svg-icons/faPencil';
import { faSave } from '@fortawesome/pro-regular-svg-icons/faSave';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import { faListUl } from '@fortawesome/pro-regular-svg-icons/faListUl';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faEdit } from '@fortawesome/pro-regular-svg-icons/faEdit';
import { faInfo } from '@fortawesome/pro-regular-svg-icons/faInfo';
import { faDownload } from '@fortawesome/pro-regular-svg-icons/faDownload';
import { faLock } from '@fortawesome/pro-regular-svg-icons/faLock';
import { faLockOpen } from '@fortawesome/pro-regular-svg-icons/faLockOpen';
import { faClipboard } from '@fortawesome/pro-regular-svg-icons/faClipboard';
import { faFileCsv } from '@fortawesome/pro-regular-svg-icons/faFileCsv';
import { faChevronUp } from '@fortawesome/pro-regular-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown';

export const FaLibrary: IconDefinition[] = [
    faAlarmClock,
    faParking,
    faKey,
    faFolderOpen,
    faBell,
    faSave,
    faPencil,
    faCloudUpload,
    faTrashAlt,
    faExclamationTriangle,
    faInfoCircle,
    faArrowRight,
    faListUl,
    faSearch,
    faEdit,
    faInfo,
    faDownload,
    faLock,
    faLockOpen,
    faClipboard,
    faFileCsv,
    faChevronUp,
    faChevronDown
];