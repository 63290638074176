import { IAuthority } from 'eos-base-app/src/AppInterfaces/IAuthority';

export const Authority: IAuthority = {
    Authority: process.env.REACT_APP_AUTHORITY,
    ClientId: 'controlling',
    RedirectUri: 'signin-callback.html',
    ResponseType: 'code',
    Scope: 'openid profile controlling baseapi',
    PostLogoutRedirectUri: 'signout-callback.html',
    AutomaticSilentRenew: true,
    SilentRedirectUri: `signin-silent-callback.html`,
    FirstPage: '/',
    NativeDeepLink: 'postlogisticscontrolling'
};