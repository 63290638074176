import { IAppProps } from 'eos-base-app/src/AppInterfaces/IAppProps';
import MessagesDe from '../Translations/de.json';
import MessagesEn from '../Translations/en.json';
import MessagesFr from '../Translations/fr.json';
import MessagesIt from '../Translations/it.json';
import { AppTitle } from './AppTitle';
import { Authority } from './Authority';
import { FaLibrary } from './FaLibrary';

export const AppProps: IAppProps = {
    AppName: 'controlling',
    BaseApiPath: process.env.REACT_APP_BASEPI,
    AppTitle: AppTitle,
    Authority: Authority,
    PublicTrackAndTrace: false,
    IconLibrary: FaLibrary as any,
    UserRegisterPage: false,
    Messages: {
        De: MessagesDe,
        Fr: MessagesFr,
        It: MessagesIt,
        En: MessagesEn,
    },
    AppDownload: {
        Android: null,
        iOS: null,
        Mac: null,
        Windows: null
    },
    PublicRoutes: []
};