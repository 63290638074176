import { App } from 'eos-base-app/src/App';
import React, { lazy } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AppProps } from './Config/AppProps';
import { isMobile } from "react-device-detect";

const InputsFinancial = lazy(() => import('./Controllers/InputData/InputsFinancialsData'));
const Contacts = lazy(() => import('eos-base-app/src/Controllers/Contacts/List'));


export class Routes extends React.Component {
   
    /**Render component */
    public render() {
        return (
            <App {...AppProps}>
                
                <Route exact path={['/', '/inputs']} component={InputsFinancial} />
               
                <Route exact path='/contacts' component={Contacts} />
                
            </App>
        );
    }
}