import 'whatwg-fetch';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { Routes } from './Routes';
import * as DotEnv from 'dotenv';

import config from "devextreme/core/config";
import './GlobalControlling.css';

config({
    editorStylingMode: 'underlined' // or 'outlined' | 'underlined'
});

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
DotEnv.config();

root.render(<Routes />);